exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bikes-js": () => import("./../../../src/pages/bikes.js" /* webpackChunkName: "component---src-pages-bikes-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-builds-js": () => import("./../../../src/pages/builds.js" /* webpackChunkName: "component---src-pages-builds-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-simpsons-js": () => import("./../../../src/pages/simpsons.js" /* webpackChunkName: "component---src-pages-simpsons-js" */),
  "component---src-templates-bike-pic-js": () => import("./../../../src/templates/BikePic.js" /* webpackChunkName: "component---src-templates-bike-pic-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-mini-build-js": () => import("./../../../src/templates/MiniBuild.js" /* webpackChunkName: "component---src-templates-mini-build-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

